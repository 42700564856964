<template>
  <div class="">
    <router-link
      :to="'/collaborations/' + collab._id.$oid"
      class="card recent-topic"
      v-for="(collab, index) in collaborationsByUser"
      :key="index"
    >
      <div class="card-image" v-if="collab.institution">
        <img
          :src="'/assets/institutions/' +
            collab.institution.id +
            '.png'
            "
          class="card-img-top"
          :title="collab.institution.name
              ? collab.institution.name
              : null
            "
        />
      </div>
      <div class="card-body">
        <div class="card-area">
          <small>{{ cutText(collab.area, 60) }}</small>
        </div>
        <hr />
        <h2 class="card-title">
          {{ cutText(collab.title, 100) }}
        </h2>
        <h6 class="card-subtitle">
          {{ collab.subtitle }}
        </h6>
        <p class="card-text">
          {{ cutText(htmlToText(collab.content), 300) }}
        </p>
        <div class="card-bottom">
          <span>{{ collab.degree }}</span
          ><span class="divider">|</span>
          <span>{{ collab.targetSemester }}</span
          ><span class="divider">|</span>
          {{ collab.members && collab.members.length }}
          members
          <!-- <span class="badge" v-if="collab.collaboration.members.includes(user.userId)">yours</span> -->
          <span class="btn btn-link float-right">View</span>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
 name: 'users-collaborations-list',
 props:['collaborationsByUser'],
 methods: {
      cutText: function (text, characters) {
      if (typeof text !== typeof undefined && text) {
        return text.length > characters
          ? text.substring(0, characters) + '...'
          : text;
      }
    },
        htmlToText(html) {
      let div = document.createElement('div');
      div.style.display = 'none';
      div.innerHTML = html;
      return div.innerText;
    },
 }
};
</script>

<style></style>
