<template>
  <div class="">
    <div class="embed-responsive mt-1 mb-4">
      <iframe src="https://player.vimeo.com/video/600589413?h=da970d434f&color=ff0179&title=0&byline=0&portrait=0"
        frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
    </div>
    <h2>
      <template v-if="isZealandFlow">
        Sådan kommer du i gang
      </template>
      <template v-else>
        Let’s get you started
      </template>
    </h2>
    <p>
      <template v-if="isZealandFlow">
        Velkommen Excelerater! Du er nu klar til at bruge Excelerate.
      </template>
      <template v-else>
        Welcome Excelerater! You’re now all set up an ready to get started on Excelerate.
      </template>
    </p>
    <p>
      <template v-if="isZealandFlow">
        Er du parat til at kickstarte din karriererejse? Udfyld din profil og få adgang til fordele og features på
        platformen. Vi lover dig, at det vil være det hele værd.
      </template>
      <template v-else>
        Ready to kickstart your career? Fill out your Excelerater Profile to access more features and benefits. We
        promise
        it will be worth it.
      </template>
    </p>
    <p>
      <template v-if="isZealandFlow">
        God fornøjelse!
      </template>
      <template v-else>
        Happy exploring!
      </template>
    </p>
    <div class="text-center">
      <router-link to="/user/student-rising-star" class="btn btn-primary btn-block btn-lg">
        <template v-if="isZealandFlow">
          Udfyld min profil
        </template>
        <template v-else>
          Fill out my profile
        </template>
      </router-link>
    </div>
  </div>
  
</template>

<script>
export default {
  computed: {
    isZealandFlow() {
      return this.$store.getters.isZealandFlow
    },
  }
};
</script>

<style></style>
