<template>
  <div class="mt-3">
    <p class="black px-3">
      <template v-if="isZealandFlow">
        Brugerniveau
      </template>
      <template v-else>
        Profile completion level
      </template>
      : <strong>{{ currentLevel }}</strong>
    </p>
    <a href="/user/student-rising-star" class="btn btn-link pink-color mobile-spacing-update_profile">
      <template v-if="isZealandFlow">
        Opdater din profil
      </template>
      <template v-else>
        Update profile
      </template>
    </a>
    <rising-star-progress-bar class="mobile_spacing"
      :currentValue="user.currentProgress && +user.currentProgress > 0 && +user.currentProgress <= 100 ? +user.currentProgress * 2 : +user.currentProgress > 100 ? 200 : 0" />
    <p class="black px-3" v-if="!user.careerMatching">
      It will take less than {{ stepTime }} minutes to reach the
      <strong>{{ nextLevel }}</strong>
      {{ currentLevel === 'Talent' ? null : 'level' }}
    </p>
    <template v-if="isZealandFlow && +user.currentProgress === 0">
      <strong class="black px-3">Med din Explorer profil kan du</strong>
      <ul class="list-margin purple">
        <li>
          <i class="fa fa-check" aria-hidden="true"></i> Bruge Excelerate til Zealand events
        </li>
        <li>
          <i class="fa fa-check" aria-hidden="true"></i> Ansøge til praktik- og jobstillinger
        </li>
        <li>
          <i class="fa fa-check" aria-hidden="true"></i> Udforske virksomheder og karrieremuligheder
        </li>
      </ul>
      <p class="black px-3">Vil du have adgang til flere fordele og features? Udfyld din profil for at blive Risting
        Star.
      </p>
    </template>
    <template v-else>
      <ul class="list-margin purple" v-if="+user.currentProgress === 0">
        <li>
          <i class="fa fa-check" aria-hidden="true"></i> Unlock Excelerate Thesis
          Collaboration Matching
        </li>
        <li>
          <i class="fa fa-check" aria-hidden="true"></i> Match with companies in
          your industry
        </li>
        <li>
          <i class="fa fa-check" aria-hidden="true"></i> Upload thesis topic /
          project proposal
        </li>
      </ul>
    </template>

    <template v-if="isZealandFlow && +user.currentProgress > 0 && +user.currentProgress <= 50">
      <strong class="black px-3">Med din Rising Star profil kan du
      </strong>

      <ul class="list-margin purple">
        <li>
          <i class="fa fa-check" aria-hidden="true"></i> 3x dine praktik- og jobmatching chancer
        </li>
        <li>
          <i class="fa fa-check" aria-hidden="true"></i> Skil dig ud fra mængden med en Talent profil
        </li>
        <li>
          <i class="fa fa-check" aria-hidden="true"></i> Adgang til Excelerate Karriere Matching-featuren
        </li>
      </ul>
      <p class="black px-3">Vil du have adgang til flere fordele og features? Udfyld din profil for at blive Talent.
      </p>
    </template>

    <template v-else>
      <ul class="list-margin purple" v-if="+user.currentProgress > 0 && +user.currentProgress <= 50">
        <li>
          <i class="fa fa-check" aria-hidden="true"></i> Unlock Excelerate Career
          Matching
        </li>
        <li>
          <i class="fa fa-check" aria-hidden="true"></i> Triple thesis
          collaboration chances
        </li>
        <li>
          <i class="fa fa-check" aria-hidden="true"></i> Boost profile to stand
          out
        </li>
      </ul>
    </template>

    <template v-if="isZealandFlow && +user.currentProgress === 100">
      <strong class="black px-3">High five! Du har færdiggjort din Talent profil</strong>
      <p class="black px-3">Tillykke! Du er nu et Excelerater Talent og har</p>
      <ul class="list-margin purple" v-if="+user.currentProgress > 0 && +user.currentProgress <= 50">
        <li>
          <i class="fa fa-check" aria-hidden="true"></i> Tredoblet dine praktik- og jobmatching chancer
        </li>
        <li>
          <i class="fa fa-check" aria-hidden="true"></i> Skilt dig ud fra mængden med en unik Talent profil
        </li>
        <li>
          <i class="fa fa-check" aria-hidden="true"></i> Fået adgang til Excelerate Karriere Matching-featuren
        </li>
        <p class="black px-3">
          Husk at tilføje de virksomheder, du ønsker at matche med. Så gør Excelerate resten af arbejdet.
        </p>
      </ul>

    </template>
    <template v-else>
      <p class="black px-3" v-if="+user.currentProgress === 100 && !user.careerMatching">
        Once you have enabled Excelerate Career Matching we will start to look for
        companies who match your profile.
      </p>
      <p class="black px-3" v-if="+user.currentProgress === 100 && user.careerMatching">
        Awesome, you have completed your profile. We are now actively looking for
        companies who match your profile.
      </p>
      <p class="black px-3" v-if="+user.currentProgress === 100 && user.careerMatching">
        Would you like to have a free call with a Excelerate consultant to talk
        about your thesis or opportunities with Excelerate?
      </p>
    </template>

    <div class="text-center">
      <router-link v-if="+user.currentProgress >= 0 && +user.currentProgress < 50" to="/user/student-rising-star"
        class="btn button-pink">
        <template v-if="isZealandFlow">

          Bliv Excelerate Rising Star
        </template>
        <template v-else>

          Become a Rising Star
        </template>
      </router-link>
      <router-link v-if="+user.currentProgress >= 50 && +user.currentProgress < 100" to="/user/student-rising-star"
        class="btn button-pink">
        <template v-if="isZealandFlow">

          Bliv Excelerate Talent
        </template>
        <template v-else>

          Become a Talent
        </template>
      </router-link>
      <router-link v-if="+user.currentProgress === 100 && !user.careerMatching" to="/user/student-talent/unlock"
        class="btn button-pink">Activate Excelerate Career Matching</router-link>
      <button v-if="+user.currentProgress === 100 && user.careerMatching && !isZealandFlow"
        onclick="Calendly.initPopupWidget({url: 'https:\/\/calendly.com/excelerate-onboarding/thesis-supervision'});return false;"
        class="btn button-pink">
        Book a call
      </button>
      <a v-if="isZealandFlow && +user.currentProgress === 100" href="https://excelerate.dk/zealand/"
        class="btn button-pink">Udforsk virksomheder og ledige stillinger</a>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { base64ToString } from '../../utils/utils';
import RisingStarProgressBar from '../RisingStarProgressBar.vue';

let role = localStorage.getItem('i_am')
role = base64ToString(role)
export default {
  components: { RisingStarProgressBar },
  computed: {
    isZealandFlow() {
      return this.$store.getters.isZealandFlow
    },
    user() {
      return this.$store.getters.studentData
    },
    nextLevel() {
      if (+this.user.currentProgress == 0 || +this.user.currentProgress >= 0 && +this.user.currentProgress < 50) {
        return 'Rising Star';
      } else if (
        this.user.currentProgress &&
        +this.user.currentProgress >= 50 &&
        +this.user.currentProgress < 100
      ) {
        return 'Talent';
      } else if (
        this.user.currentProgress &&
        +this.user.currentProgress === 100 &&
        !this.user.careerMatching
      ) {
        return 'Excelerate Career Matching.';
      }
      return null;
    },
    currentLevel() {
      if (+this.user.currentProgress == 0 || +this.user.currentProgress >= 0 && +this.user.currentProgress < 50) {
        return 'Explorer';
      } else if (
        this.user.currentProgress &&
        +this.user.currentProgress >= 50 &&
        +this.user.currentProgress < 100
      ) {
        return 'Rising Star';
      } else if (this.user.currentProgress && +this.user.currentProgress === 100) {
        return 'Talent';
      }
      return null;
    },
    stepTime() {
      return +this.user.currentProgress < 100 ? 5 : 2;
    },
  },
};
</script>

<style scoped>
ul li {
  list-style: none;
}

@media (max-width: 599px) {
  .mobile_spacing {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .mobile-spacing-update_profile {
    margin-bottom: 20px;
  }
}
</style>
