<template>
  <div>
    <MasterHeader title="Dashboard" />
    <loader :times="2" v-if="!user"></loader>
    <template>
      <!-- <sign-up-modal v-if="hasNewSignupFlow"/> -->
      <div class="dashboard" v-if="user && role === 'professional'">
        <div class="container container-wide">
          <div class="row">
            <div class="col-12">
              <div class="widget">
                <div class="widget-splash splash-line"></div>
                <div class="widget-header">
                  <h4>Welcome</h4>
                  <div class="widget-navigation">
                    <router-link :to="'/user/profile-edit'" class="widget-nav"><i class="fas fa-user-edit"></i>Edit
                      profile</router-link>
                  </div>
                </div>
                <div class="widget-body profile">
                  <!-- not even sure why this part exists -->
                  <div class="row" v-if="role === 'student'">
                    <div class="col-12 col-md-8">
                      <h4>
                        {{ user.firstName }} {{ user.lastName }}
                        <!-- <span class="badge">student</span> -->
                      </h4>
                      <div class="" v-if="false">
                        {{ user.institution.name }} | {{ user.faculty }} |
                        {{ user.degree }} | {{ user.area }} |
                        {{ user.secondaryArea }} | Graduating
                        {{ user.graduatingSemester }}
                      </div>
                    </div>
                    <div class="col-12 col-md-4 text-right">
                      <img v-if="false" :src="'/assets/institutions/' + user.institution.id + '.png'
                        " class="img-fluid" style="max-width: 300px" :title="user.institution.name" />
                    </div>
                  </div>

                  <div class="row mb-3">
                    <div class="col-12 col-md-8">
                      <h4>{{ user.firstName }} {{ user.lastName }}</h4>
                      <!-- <div class="">
                                         {{ user.favorites.length}} bookmarks
                                    </div> -->
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12 col-md-3">
                      Email
                      <div class="headline">
                        {{ user.email }}
                      </div>
                    </div>
                    <div class="col-12 col-md-3">
                      Phone
                      <div class="headline">
                        {{ user.phoneNumber ? user.phoneNumber : null }}
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      Interests (Do professionals have interests now?)
                      <div class="headline">
                        {{ user.interests ? user.interests.name : null }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="col-12" style="background-color: green;" v-if="this.$route.params.widget === 'welcome'">
                    welcome NEW user. -> start guide
                </div> -->

            <!-- PROFESSIONAL - YOU HAVE BEEN INVITED TO A COMPANY -->
            MOST LIKELY WE"LL REMOVE THIS. WILL DISCUSS WITH KASPAR IN A COUPLE DAYS

            <div class="col-12 col-md-6" v-if="companyInvites &&
              companyInvites.length > 0 &&
              user.company.length === 0
              ">
              <div class="widget">
                <div class="widget-splash" style="
                    background-image: url('/assets/company-invitation.jpg');
                  "></div>
                <div class="widget-header">
                  <h4>Invite</h4>
                  <!-- <div class="widget-navigation">
                                <router-link :to="'/company/new'" class="widget-nav"><i class="fas fa-plus"></i>Register</router-link>
                            </div> -->
                </div>
                <div class="widget-body">
                  <div v-for="(invite, index) in companyInvites" :key="index" class="erow row d-flex">
                    <div class="col-1 align-self-center">
                      <i class="fas fa-envelope"></i>
                    </div>
                    <div class="col-5 align-self-center">
                      <div class="">
                        {{ invite.invites.sender }} invites you to join
                      </div>
                      <div class="headline">
                        {{ invite.invites.companyName }}
                      </div>
                    </div>
                    <div class="col-6 align-self-center text-right">
                      <button type="button" class="btn btn-link color-third mr-2"
                        @click="declineCompanyInvitation(invite)">
                        Decline
                      </button>
                      <button type="button" class="btn btn-primary" @click="acceptCompanyInvitation(invite)">
                        Accept
                      </button>

                      <!-- <i class="fas fa-ban cursor-pointer mr-3" title="Decline invitation"></i> -->
                      <!-- <i class="fas fa-check color-first cursor-pointer" @click="acceptCompanyInvitation(invite)" title="Accept invitation"></i> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- PROFESSIONAL - REGISTER COMPANY -->
            | DO WE GIVE THE CREATE COMPANY OPTION NOW?
            <div class="col-12 col-md-6" v-if="(user.company &&
              user.company.length === 0 &&
              companyInvites &&
              companyInvites.length === 0) ||
              (user.company === null)
              ">
              <div class="widget">
                <div class="widget-header">
                  <h4>Guide</h4>
                  <div class="widget-navigation">
                    <!-- <router-link :to="'/company/new'" class="widget-nav"><i class="fas fa-plus"></i>Register</router-link> -->
                  </div>
                </div>
                <div class="widget-body">
                  <div class="mb-3">
                    <h2>Next step</h2>
                    <p>
                      To use the Excelerate platform you have to register your
                      company.<br />
                      If your company is already using Excelerate, they can send
                      you an invite to join the company group.
                    </p>
                    <div class="text-center">
                      <router-link :to="'/company/new'" class="btn btn-primary btn-lg btn-block mt-2">Register your
                        company</router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- STUDENT COLLABORATION WIDGET -->
            <!-- MAKES NO SENSE ANYMORE -->
            <div v-if="role === 'student'" class="col-12 col-md-6">
              <div class="widget">
                <div class="widget-header sticky">
                  <h4>Published collaborations</h4>
                  <div class="widget-navigation">
                    <router-link :to="'/collaboration/my'" class="widget-nav"><i class="fas fa-hands-helping"></i>My
                      collaboration</router-link>
                  </div>
                </div>
                <div class="widget-body">
                  <template v-if="collaborationsByUser && collaborationsByUser.length">
                    <router-link :to="'/collaborations/' + collab.id" class="card"
                      v-for="(collab, index) in collaborationsByUser" :key="index">
                      <div class="card-image">
                        <img v-if="false" :src="'/assets/institutions/' +
                          collab.collaboration.institution.id +
                          '.png'
                          " class="card-img-top" :title="collab.collaboration.institution.name
    ? collab.collaboration.institution.name
    : null
    " />
                      </div>
                      <div class="card-body">
                        <div class="card-area" v-if="false">
                          <small>{{
                            cutText(collab.collaboration.area, 60)
                          }}</small>
                        </div>
                        <hr />
                        <h2 class="card-title">
                          {{ cutText(collab.collaboration.title, 100) }}
                        </h2>
                        <h6 class="card-subtitle">
                          {{ collab.collaboration.subtitle }}
                        </h6>
                        <p class="card-text">
                          {{ cutText(collab.collaboration.content, 300) }}
                        </p>
                        <div class="card-bottom">
                          <span>{{ collab.collaboration.degree }}</span><span class="divider">|</span>
                          <span>{{ collab.collaboration.targetSemester }}</span><span class="divider">|</span>
                          {{
                            collab.collaboration.members &&
                            collab.collaboration.members.length
                          }}
                          members
                          <!-- <span class="badge" v-if="collab.collaboration.members.includes(user.userId)">yours</span> -->
                          <span class="btn btn-link float-right">View</span>
                        </div>
                      </div>
                    </router-link>
                  </template>

                  <div class="" v-else>
                    <div class="mb-3">
                      <h2>Next step</h2>
                      <p>
                        Fill out the thesis title and description, save the
                        draft and invite your thesis group to join you here.<br />
                        <br />
                        Click ‘start collaborating’ to get going
                      </p>
                      <div class="text-center">
                        <router-link :to="'/collaboration/new'" class="btn btn-primary btn-block btn-lg">Start
                          collaborating</router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- PROFESSIONAL - OFFERS -->
            <!-- <div v-if="role === 'Professional' && user.company.length > 0 && companyOffers.length > 0" class="col-12 temp-widget">
                    <div class="widget">
                        <div class="widget-header">
                            <h4>Latest negotiations</h4>
                        </div>
                        <div class="widget-body">
                            <router-link :to="'/collaborations/' + offer.offer.collaborationId" v-for="(offer, index) in companyOffers" :key="index" class="erow row link d-flex">
                                <div class="col-1 align-self-center">
                                    <i class="fas fa-hands-helping" ></i>
                                </div>
                                <div class="col-5 align-self-center">
                                    Title
                                    <div class="headline">
                                        <span class="highlight">{{offer.offer.collaborationTitle}}</span>
                                    </div>
                                </div>
                                <div class="col-3 align-self-center">
                                    Status
                                    <div class="headline">
                                        Waiting for your accept
                                    </div>
                                </div>
                                <div class="col-3 align-self-center text-right">
                                    <span class="btn btn-link color-third">View</span>
                                </div>
                            </router-link>
                            <div class="text-center">
                                <router-link :to="'/companies/' + user.company" class="btn btn-primary">
                                    View all negotiations 
                                </router-link>
                            </div>

                        </div>
                    </div>
                </div> -->

            <!-- NO SENSE ANYMORE -->
            <div class="col-12 col-md-6">
              <!-- STUDENT COLLABORATION INVITES -->
              <div v-if="role === 'student' &&
                collaborationInvites &&
                collaborationInvites.length > 0
                ">
                <div class="widget">
                  <div class="widget-header">
                    <h4>Invites</h4>
                    <div class="widget-navigation">
                      <router-link :to="'/collaboration/my'" class="widget-nav"><i class="fas fa-link"></i>My
                        collaborations</router-link>
                    </div>
                  </div>
                  <div class="widget-body">
                    <router-link :to="'/collaborations/' +
                      invite.invites.collaborationId +
                      '#members'
                      " v-for="(invite, index) in collaborationInvites" :key="index" class="erow row link d-flex">
                      <div class="col-1 align-self-center">
                        <i class="fas fa-envelope"></i>
                      </div>
                      <div class="col-4 align-self-center">
                        From
                        <!-- <div class="headline">{{ invite.invites.sender }}</div> -->
                      </div>
                      <div class="col-5 align-self-center">
                        Collaboration
                        <div class="headline">
                          <!-- {{ cutText(invite.invites.collaborationTitle, 40) }} -->
                        </div>
                      </div>
                      <div class="col-2 align-self-center">
                        <span class="btn btn-link">View</span>
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>

              <div class="widget">
                <div class="widget-header">
                  <h4>Lastest topics</h4>
                  <div class="widget-navigation">
                    <!-- <router-link :to="'/collaborations/'" class="widget-nav color-third"><i class="fas fa-search"></i>Find collaborations</router-link> -->
                  </div>
                </div>
                <div class="widget-body">
                  <router-link :to="'/collaborations/' + collab._id.$oid"
                    v-for="(collab, index) in latestCollaborations" :key="index">
                    <div class="erow">
                      <div class="row">
                        <div class="col-12 col-md-4">
                          <img v-if="collab.institution" :src="'/assets/institutions/' +
                            collab.institution.id +
                            '.png'
                            " class="img-fluid" />

                          <!-- <div class="mt-4">
                                            <i class="fas fa-user-graduate"></i> 3 students
                                        </div> -->
                        </div>
                        <div class="col-12 col-md-8">
                          <div class="headline mb-2">
                            {{ collab.title }}
                          </div>
                          <span class="btn btn-link color-third btn-sm">View collaboration</span>
                        </div>
                      </div>
                    </div>
                  </router-link>

                  <div class="text-center">
                    <router-link to="/collaborations/" class="btn btn-secondary">
                      Find collaborations
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="dashboard" v-if="user && role === 'student'">
        <div class="container-fluid">
          <div v-if="role === 'student'" class="mobile_only">
            <div v-for="event in ongoingEvents" :key="event.event_id" class="event-card mb-4">
              <h3>{{ event.widget_title }}</h3>
              <p>{{ event.widget_text }}</p>
              <div class="text-right">
                <router-link to="/events" class="btn btn-white">{{ event.widget_button }}</router-link>
              </div>
            </div>
            <div v-for="event in promotionalEvents" :key="event.event_id" class="event-card mb-4">
              <h3>{{ event.widget_title }}</h3>
              <p>{{ event.widget_text }}</p>
              <div class="text-right">
                <router-link to="/events" class="btn btn-white">{{ event.widget_button }}</router-link>
              </div>
            </div>
          </div>
          <div class="row mobile_flex_direction">
            <div class="col-12 col-md-6">
              <!-- STUDENT COLLABORATION INVITES -->
              <div v-if="role === 'student' &&
                collaborationInvites &&
                collaborationInvites.length > 0
                ">
                <div class="widget widget-invite">
                  <div class="widget-splash splash-line"></div>
                  <div class="widget-header">
                    <h4>Invitation to thesis group</h4>
                    <!-- <div class="widget-navigation">
                                    <router-link :to="'/collaboration/my'" class="widget-nav"><i class="fas fa-link"></i>My collaborations</router-link>
                                </div> -->
                  </div>
                  <div class="widget-body">
                    <router-link :to="'/collaborations/' +
                      invite.collaborationId +
                      '#members'
                      " v-for="(invite, index) in collaborationInvites" :key="index" class="erow row link d-flex">
                      <div class="col-1 align-self-center">
                        <i class="fas fa-envelope"></i>
                      </div>
                      <div class="col-4 align-self-center">
                        <h6>From</h6>
                        <p class="headline">{{ invite.sender }}</p>
                      </div>
                      <div class="col-5 align-self-center">
                        <h6>Thesis Topic</h6>
                        <p class="headline">
                          {{ cutText(invite.collaborationTitle, 40) }}
                        </p>
                      </div>
                      <div class="col-2 align-self-center">
                        <button type="button" class="btn btn-sm btn-primary" v-if="invite.targetEmail === user.email &&
                          role !== 'Professional'
                          " @click="acceptInvitation($event, invite)" title="Accept invite">
                          Accept
                        </button>
                        <button type="button" class="btn btn-sm btn-link mr-1" v-if="invite.targetEmail === user.email &&
                          role !== 'Professional'
                          " @click="declineInvitation($event, invite)" title="Cancel invitation">
                          Decline
                        </button>
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>
              <div class="desktop_event" style="">
                <div v-if="role === 'student'" class="only_desktop">
                  <div v-for="event in ongoingEvents" :key="event.event_id" class="event-card mb-4">
                    <h3>{{ event.widget_title }}</h3>
                    <p>{{ event.widget_text }}</p>
                    <div class="text-right">
                      <router-link to="/events" class="btn btn-white">{{ event.widget_button }}</router-link>
                    </div>
                  </div>
                  <div v-for="event in promotionalEvents" :key="event.event_id" class="event-card mb-4">
                    <h3>{{ event.widget_title }}</h3>
                    <p>{{ event.widget_text }}</p>
                    <div class="text-right">
                      <router-link to="/events" class="btn btn-white">{{ event.widget_button }}</router-link>
                    </div>
                  </div>
                </div>
                <div class="widget">
                  <div class="widget-splash splash-line"></div>
                  <div class="widget-header">
                    <template v-if="collaborationsByUser && collaborationsByUser.length > 0">
                      <h4>Thesis Topic</h4>
                      <div class="widget-navigation">
                        <router-link :to="'/collaboration/my'" class="widget-nav"><i class="fas fa-hands-helping"></i>My
                          collaborations</router-link>
                      </div>
                    </template>
                    <template v-else>
                      <h4>
                        <template v-if="isZealandFlow">
                          Velkommen til Excelerate
                        </template>
                        <template v-else>
                          Welcome to Excelerate
                        </template>
                      </h4>
                    </template>
                  </div>
                  <div class="widget-body widget-suggestion">
                    <template v-if="collaborationsByUser">
                      <user-collaborations-list :collaborationsByUser="collaborationsByUser" />
                    </template>
                    <create-your-first-thesis v-else />
                  </div>
                </div>
              </div>
              <book-consult />
            </div>
            <div class="col-12 col-md-6">
              <div class="widget">
                <div class="widget-splash splash-line"></div>
                <div class="widget-header flex-header">
                  <h4 v-if="isZealandFlow">Profil</h4>
                  <h4 v-else>Profile</h4>
                  <div class="widget-navigation">
                    <router-link :to="'/user/profile-edit'" class="widget-nav"><i class="fas fa-user-edit"></i>
                      <template v-if="isZealandFlow">
                        Rediger profil
                      </template>
                      <template v-else>
                        Edit profile
                      </template>
                    </router-link>
                  </div>
                </div>
                <div class="widget-body profile">
                  <div class="row" v-if="role === 'student'">
                    <div class="col-12">
                      <h4>
                        {{ user.firstName }} {{ user.lastName }}
                        <!-- <span class="badge">student</span> -->
                      </h4>
                      <div class="">
                        {{ user.otherEducationalInstitution ? `${user.otherEducationalInstitution} |` : user.institution.name ? `${user.institution.name} |` : '' }}
                        {{ user.faculty ? `${user.faculty} |` : '' }}
                        {{ user.degree ? `${user.degree} |` : '' }} {{ user.area ? `${user.area} |` : '' }}
                        {{ user.otherProgrammeName ? `${user.otherProgrammeName} |` : user.secondaryArea ? `${user.secondaryArea} |` : '' }}
                        {{ user.graduatingYear ? `${graduatingLabel} ${user.graduatingYear}` : '' }}
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="student-profile" v-if="isLoaded">
                      <complete-signup />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
// import moment from 'moment'
import { mapState } from 'vuex';
import MasterHeader from '@/components/MasterHeader.vue';
// import Loader from '@/components/Loader.vue';
import CompleteSignup from '../../components/student/CompleteSignup.vue';
import SignUpModal from '../../components/signUpModal.vue';
import CreateYourFirstThesis from '../../components/student/CreateYourFirstThesis.vue';
import BookConsult from '../../components/student/BookConsult.vue';
import Loader from '../../components/Loader.vue';
import UserCollaborationsList from '../../components/student/userCollaborationsList.vue';
import { base64ToString } from '../../utils/utils';
import collaborationService from '../../services/collaborationService';

import moment from 'moment'

let userRole = localStorage.getItem('i_am')
userRole = base64ToString(userRole)
let userId = base64ToString(localStorage.getItem('uid'))

export default {
  // metaInfo: {
  //   script: [
  //     { src: 'https://assets.calendly.com/assets/external/widget.js', async: true, defer: true }
  //   ],
  // },
  data: function () {
    return {
      email: '',
      // lastestCollaborations: '',
      steps: 10,
      active_steps: 5,
      profile: {},
      openDescriptionEditor: false,
      characters: 0,
      isCharactersLimitExceeded: false,
      openPassionEditor: true,
      passionCharacter: 0,
      isPassionCharactersLimitExceeded: false,
      hardSkillsData: false,
      coreSkillsData: false,
      jobsData: false,
      openDesiredJobSelector: true,
      progress_status: 'Basic',
      isGoingToNextStep: true,
      jobIsEmpty: true,
      newlyCompleted: false,
      activeEvents: [1],
      collaborationInvites: null,
    };
  },
  metaInfo: {
    title: 'Your Dashboard',
    meta: [
      {
        name: 'description',
        content:
          'A digital platform that connects students and companies via thesis collaboration matching.',
      },
      { property: 'og:title', content: 'Your Dashboard' },
      {
        property: 'og:description',
        content:
          'A digital platform that connects students and companies via thesis collaboration matching.',
      },
    ],
  },
  components: {
    MasterHeader,
    // Loader,
    CompleteSignup,
    CreateYourFirstThesis,
    BookConsult,
    Loader,
    UserCollaborationsList,
    //SignUpModal,
  },
  created() {
    let userType = localStorage.getItem('i_am')
    userType = base64ToString(userType)
    let userVerified = localStorage.getItem('u-verified')

    if ((userVerified == 0 || !userVerified) && userType == 'student') {
      this.$router.push('/user/student-welcome')
    }
    if (userVerified == 0 && userType == 'professional') {
      this.$router.push('/user/welcome')
    }
    collaborationService.getUserInvitations().then((res) => {
      this.collaborationInvites = res
    }).catch(() => {
      this.invitationFetchError = 'Failed to fetch invitations...'
    })
  },
  mounted() {
    if (userRole === 'student' && this.$store.getters.userCollaborations && this.$store.getters.userCollaborations.length === 0) {
      this.$store.dispatch('getAllUserCollaborations', userId)
    }
    if (userRole === 'student' && !this.$store.getters.studentData) {
      this.$store.dispatch('getStudentData', userId);
    } else if (userRole === 'professional' && !this.$store.getters.professionalData) {
      this.$store.dispatch('getProfessionalData', userId);
    }
    // if (!this.$store.getters.events) {
    // }
    if (!this.$store.getters.latestCollaborations || this.$store.getters.latestCollaborations.length === 0) {
      this.$store.dispatch('getLatestCollaborations')
    }

    // ? just for good measure loading these up in the background to have updated data at all times
    this.$store.dispatch('getEvents')
    this.$store.dispatch('getAllCollaborations');
  },
  computed: {
    role() {
      return userRole
    },
    isZealandFlow() {
      return this.$store.getters.isZealandFlow
    },
    user() {
      if (userRole === 'student') {
        return this.$store.getters.studentData
      }
      if (userRole === 'professional') return this.$store.getters.professionalData
      return false
    },
    latestCollaborations() {
      return this.$store.getters.latestCollaborations
    },
    ongoingEvents() {
      if (this.$store.getters.events && this.$store.getters.events.ongoingevent.length > 0) {
        return this.$store.getters.events.ongoingevent
      }
      return false
    },
    promotionalEvents() {
      if (this.$store.getters.events && this.$store.getters.events.futureevent.length > 0) {
        const events = []
        const today = moment()
        for (let event of this.$store.getters.events.futureevent) {
          const promotionStartDate = moment(event.start_promotion_date, 'YYYY-MM-DD HH:mm:ss');
          const promotionEndDate = moment(event.end_promotion_date, 'YYYY-MM-DD HH:mm:ss');
          if (today.isAfter(promotionStartDate) && today.isBefore(promotionEndDate)) {
            events.push(event)
          }
        }
        return events
      }
      return false
    },
    isLoaded: function () {
      var vm = this;
      var result = true;
      // if (this.user.userId !== null) {
      //   if(!this.$store.getters.verifyingUser) {
      //     if (!this.user.verified && this.role === 'student'){
      //       vm.$router.push({ path: '/user/student-welcome' })
      //     } else if(!this.user.verified && this.role === 'Professional') {
      //       vm.$router.push({ path: '/user/student-welcome' })
      //     }
      //   }
      //   let profile = JSON.parse(JSON.stringify(vm.$store.state.user));
      //   if (profile.isCompleted && typeof profile != 'undefined') {
      //     vm.profile = profile;
      //   } else {
      //     vm.profile = {
      //       ...{
      //         hardSkills: {},
      //         coreSkills: {},
      //         jobs: [],
      //         description: '',
      //         passion: '',
      //       },
      //       ...profile,
      //     };
      //   }
      //   result = true;
      // }
      return result;
    },
    collaborationsByUser: function () {
      return this.$store.getters.userCollaborations
    },
    companyInvites: function () {
      // return this.$store.state.company.usersCompanyInvites;
      return []
    },
    companyOffers: function () {
      // return this.$store.state.company.companyOffers;
      return []
    },
    desIsEmpty: function () {
      let res = true;
      // if (this.$store.state.user.userId !== null) {
      //   res = this.profile.description != '' ? false : true;
      // }
      return res;
    },
    passionIsEmpty: function () {
      let res = true;
      // if (this.$store.state.user.userId !== null) {
      //   res = this.profile.passion != '' ? false : true;
      // }
      return res;
    },
    // companyCollaborations: function() {
    //     return this.$store.state.company.companyCollaborations;
    // }
    graduatingLabel() {
      const currentYear = new Date().getFullYear()
      return +this.user.graduatingYear < +currentYear ? 'Graduated' : 'Graduating';
    }
  },
  methods: {
    filterLineBreaks(text) {
      return text.replace(/\r?\n/g, '<br/>');
    },
    handleJobChange(job) {
      this.profile.jobs = job;
    },
    // We want to display the name
    getResultValue(result) {
      return result.name;
    },
    async nextStep(step) {
      // if (this.active_steps == '7') {
      //   this.validateHardSkills();
      // }
      // if (this.active_steps == '8') {
      //   this.validateCoreSkills();
      // }
      // if (this.isGoingToNextStep) {
      //   this.active_steps = this.active_steps + 1;
      // }
    },
    updateStudentProfile() {
      //for students Only
      // if (this.role === 'Student') {
      //   this.profile.isCompleted = true;
      //   this.$store.dispatch('updateUserDetails', this.profile);
      // }
    },
    validateCharectors(type) {
      // if (type === 'passion') {
      //   this.passionCharacter = this.profile.passion.length;
      //   if (this.passionCharacter > 2000) {
      //     this.isPassionCharactersLimitExceeded = true;
      //   } else {
      //     this.isPassionCharactersLimitExceeded = false;
      //   }
      // } else {
      //   this.characters = this.profile.description.length;
      //   if (this.characters > 2000) {
      //     this.isCharactersLimitExceeded = true;
      //   } else {
      //     this.isCharactersLimitExceeded = false;
      //   }
      // }
    },
    acceptCompanyInvitation(payload) {
      var vm = this;
      //TODO: MAIL 'Professional - New professional Member Joined' -> senderEmail @param1: user.firstName, param2: user.lastName

      const templateData = {
        toEmail: payload.invites.senderEmail,
        firstName: vm.user.firstName,
        lastName: vm.user.lastName,
        param1: payload.invites.sender,
        param2: payload.invites.companyId,
        template: 'Professional - New professional Member Joined',
      };
      vm.$store.dispatch('sendMail', templateData);

      this.$store.dispatch('acceptCompanyInvitation', payload);
    },
    declineCompanyInvitation(payload) {
      var vm = this;
      //TODO: MAIL 'Professional - Declined invitation'
      //receiver email: senderEmail
      //firstName (this user), lastName (this user), param1: sender

      const templateData = {
        toEmail: payload.invites.senderEmail,
        firstName: vm.user.firstName,
        lastName: vm.user.lastName,
        param1: payload.invites.sender,
        param2: payload.invites.companyId,
        template: 'Professional - Declined invitation',
      };
      vm.$store.dispatch('sendMail', templateData);

      this.$store.dispatch('deleteUsersCompanyInviteById', payload);
    },
    cutText: function (text, characters) {
      if (!text) return;
      if (typeof text !== typeof undefined) {
        return text.length > characters
          ? text.substring(0, characters) + '...'
          : text;
      }
    },
    declineInvitation(e, invite) {
      e.preventDefault();

      const data = {
        collaborationId: invite.collaborationId,
        targetEmail: invite.targetEmail,
        type: 1,
      };
      collaborationService.cancelThesisInvite(data).then(() => {
        collaborationService.getUserInvitations().then((res) => {
          this.collaborationInvites = res
        }).catch(() => {
          this.invitationFetchError = 'Failed to fetch invitations...'
        })
      })
      // if(invite.invites.targetEmail == this.user.email){
      // const templateData = {
      //   toEmail: invite.invites.targetEmail,
      //   param1: this.user.firstName,
      //   template: 'Student - Declined invitation',
      // };
      // this.$store.dispatch('sendMail', templateData);
      // // }
      // // console.log(invite);
      // this.$store.dispatch('deleteCollaborationInvite', data);
      // this.$store.dispatch('getCollaborationInvitesByUserId'); // deleteCollaborationInviteById does this now..
    },
    acceptInvitation(e, invite) {
      e.preventDefault();
      var vm = this;
      var data = {
        collaborationId: invite.collaborationId,
        targetEmail: invite.targetEmail,
      };
      collaborationService.acceptThesisInvitation(data).then(() => {
        collaborationService.getUserInvitations().then((res) => {
          this.collaborationInvites = res
          this.$store.dispatch('getAllUserCollaborations')
        }).catch(() => {
          this.invitationFetchError = 'Failed to fetch invitations...'
        })
      })
      // vm.$store.dispatch('acceptCollaborationInvitationFromHome', data);

      // //MAIL 'Student - New Student Member Joined'
      // const templateData = {
      //   toEmail: invite.invites.senderEmail,
      //   firstName: vm.user.firstName,
      //   lastName: vm.user.lastName,
      //   param1: invite.invites.title,
      //   param2: invite.invites.collaborationId,
      //   template: 'Student - New Student Member Joined',
      // };
      // vm.$store.dispatch('sendMail', templateData);
      // // vm.$store.dispatch('getCollaborationInvitesByUserId');
      // // this.$store.dispatch('getCollaborationInvitesByCollabId', this.$route.params.id);
    },
    htmlToText(html) {
      let div = document.createElement('div');
      div.style.display = 'none';
      div.innerHTML = html;
      return div.innerText;
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard {
  .recent-topic {

    h2,
    h6 {
      color: #111;
    }
  }

  .widget-suggestion {
    font-size: 14px;
    color: #333;
  }

  .temp-widget {
    margin-bottom: 10px;

    .widget-content {
      border-radius: 7px;
      color: #333;
      background: #ffffff;
      border: 1px solid #f1f1f1;
      box-shadow: 3px 3px 6px #f1f1f1;
      padding: 20px;
      font-size: 20px;

      margin-bottom: 10px;
    }
  }

  .widget-header {
    color: #000;
  }

  .profile {
    padding: 1rem;

    h2 {
      color: #333;
    }

    h6 {
      font-weight: 400;
      color: #333;
      margin: 20px 1rem 0;
      font-size: 14px;

      b {
        font-weight: bold;
      }
    }

    p {
      color: #333;
      padding: 0 1rem;
      font-size: 14px;
    }

    .tip {
      font-style: italic;
    }

    .student-profile {
      width: 100%;
    }

    .offer-steps {
      padding: 0 1rem;
      margin-top: 8px;
      margin-bottom: 20px;

      ul {
        width: 100%;
      }

      .step {
        display: block;
        border: none;
        background: #d5d5d5;
        height: 9px;
      }

      .active .step {
        background: #d22d89;
      }

      ul li {
        width: 9.28%;
        margin-right: 0.2rem;
      }
    }

    .student-next {
      text-align: center;
    }

    .btn-outline {
      border: 1px solid #d22d89;
      color: #d22d89;
      padding: 0.5rem 2rem;
      font-size: 16px;
      font-weight: 600;
    }

    .btn-outline:hover {
      background: #d22d89;
      color: #fff;
    }

    .progess-steps {
      padding: 0 1rem;

      .ps-action {
        text-align: center;
        margin-top: 1rem;
        clear: both;
      }

      h6 {
        margin: 0;
        padding: 1rem 0;
        font-weight: 600;

        span.jobs-counter {
          color: #d22d89;
          font-weight: 400;
        }
      }

      textarea {
        width: 100%;
        display: block;
        padding: 1rem;
        outline: none;
        border: 1px solid #ddd;
        border-radius: 4px;
        background: #fcfcfc;
      }

      .charector-counter {
        text-align: right;
        font-size: 12px;
        margin-top: 2px;
      }

      textarea:focus-visible {
        border: 1px solid #ccc;
      }

      textarea.limit-exceeded {
        border-color: red;
      }

      .input-group label {
        color: #333;
        font-size: 12px;
      }
    }

    .profile-content h6 {
      margin-bottom: 0.4rem;
    }

    .input-group div {
      width: 100%;
    }

    .profile-skills ul li {
      float: left;
      list-style: none;
      border: 1px solid #da518a;
      padding: 0.2rem 0.5rem;
      border-radius: 8px;
      margin-right: 0.4rem;
      margin-bottom: 0.4rem;
      color: #da518a;
      font-size: 12px;
    }

    .profile-skills ul li:last-child {
      margin-bottom: 1rem;
    }

    .profile-skills ul {
      padding: 1rem 1rem 3rem;
      display: block;
    }

    .profile-content {
      clear: both;
    }

    .profile-skills ul li.profile-skill-core {
      border: 1px solid #4c2f71;
      color: #4c2f71;
    }

    .profile-skills ul li.profile-skill-jobs {
      border: 1px solid #4c9674;
      color: #4c9674;
    }

    .profoile-jobs {
      display: block;
      margin-bottom: 1rem;
    }

    .profoile-jobs ul.job-ctg {
      list-style: none;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
    }

    .profoile-jobs ul.job-ctg li {
      float: left;
      margin-bottom: 0.4rem;
    }

    .profoile-jobs ul.job-ctg li a {
      border: 1px solid #d22d89;
      padding: 0.4rem 1.2rem;
      display: inline-block;
      color: #333;
      margin-right: 1rem;
      border-radius: 14px;
      cursor: pointer;
      position: relative;
      font-weight: 700;
    }

    ul.job-ctg li a span {
      display: block;
      font-size: 10px;
      color: #333;
      pointer-events: none;
      font-weight: 500;
    }

    ul.job-ctg li:hover a,
    ul.job-ctg li.active a {
      color: #d22d89;
    }

    ul.job-ctg li.active a {
      padding: 0.4rem 2rem;
      margin-bottom: 1rem;
    }

    .dashboard .profile .profoile-jobs ul.job-ctg li.active {
      float: none;
      width: 100%;
    }

    ul.job-lists li label {
      color: #333;
      margin-bottom: 0;
    }

    .pj-loading {
      width: 100%;
      height: 201px;
      background: rgb(235 235 235);
      top: 0;
      left: 0;
      position: relative;
      border-radius: 8px;
    }

    .pj-loading i {
      position: absolute;
      top: 46%;
      left: 46%;
      font-size: 36px !important;
      color: #d22d89;
      transform: translate(-50%, -50%);
    }
  }

  .pj-selected {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;
  }

  .pj-selected span {
    color: #333;
    font-weight: 500;
    margin-right: 1rem;
  }

  .pj-selected ul {
    list-style: none;
    padding: 0;
  }

  .pj-selected ul li {
    float: left;
    margin-right: 1rem;
    color: #d22d89;
  }

  .pj-selected ul li i {
    display: inline-block;
    width: 16px;
    height: 16px;
    background: #d22d89;
    border-radius: 50%;
    color: #fff;
    font-size: 10px !important;
    padding: 0 5px;
    line-height: 1.5;
    cursor: pointer;
  }
}

.widget-invite h6 {
  color: #000;
  font-weight: 600;
  font-size: 18px;
  margin: 0;
}

.widget.widget-invite p {
  margin: 0;
  color: #000;
}
</style>
<style>
.input-group input {
  padding: 8px 8px 8px 44px;
  font-size: 14px;
  margin-bottom: 1rem;
  background-size: 16px;
  border-radius: 4px;
  background-color: transparent;
}

.autocomplete-result {
  padding: 8px 8px 8px 44px;
  background-size: 16px;
}

.autocomplete-result-list {
  margin-top: -1rem !important;
}

.active a span:after {
  content: '';
  width: 0;
  height: 0;
  border-right: 1.6rem solid transparent;
  border-left: 1.6rem solid transparent;
  /* border-top: 1.5rem solid #CCCCCC; */
  position: absolute;
  bottom: -10px;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-top: 0.4rem solid #d22d89;
}

.hard-skills.required input,
.core-skills.required input {
  border: 1px solid red;
}

.erow .headline {
  font-weight: 400;
  color: #361d49;
  font-size: 16px;
  color: #333;
}

.embed-responsive {
  height: 325px;
}

.embed-responsive iframe {
  width: 100%;
  /* height: 261px; */
}
</style>
<style>
@media (max-width: 599px) {
  .mobile_flex_direction {
    flex-direction: column-reverse!important;
  }
  .mobile_only {
    display: block!important; /* Make it visible on screens smaller than 599px */
  }
}

@media (min-width: 600px) { /* Change to min-width for screens larger than 767px */
  .only_desktop {
    display: block!important; /* Make it visible on screens larger than 767px */
  }
}

/* Hide these elements by default, they will be shown based on the media queries */
.mobile_only,
.only_desktop {
  display: none;
}

.flex-header {
  display: flex;
  /* Use flexbox layout */
  justify-content: space-between;
  /* Space out the children */
  align-items: center;
  /* Align items vertically */
}
</style>